
/**
 * Method used to set 'edited' option in uischema to true if field is edited on form
 * @param data is the change value of the field in form
 * @param submission is the form submission object
 * @param path is the string that represents the path of the field which is being edited
 * @param uischema is the uischema of the particular field which is edited
 * @returns uischema after updating the edited option
 */
export const setEditedValueinOption = (data: any, submission: any, path: string, uischema: any) => { 
    const originalData: any = submission?.originalData; 

    if(originalData && uischema?.options){ 
        uischema.options.edited = false; 
        let dataPath = path;
        if(path.match(/\d+/) && uischema.options.labelScope){ //check path represents a child record or not
          dataPath =  path+'.'+uischema.options.labelScope;
        }
        const originalValue: any = getValueByPath(originalData, dataPath); 

        //checking original value is undefined or not and then allowing 
        //because CQForm might launch with empty values and after making changes it is edited
        //after setting to empty values it should edited=false
        if((originalValue || data) && originalValue !== data){ 
            uischema.options.edited = true; 
        }
      }
    return uischema;
} 

/**
 * This method used to return value by the path and using the form data
 * @param data 
 * @param path 
 * @returns value that gets by using path and data
 */
export const getValueByPath = (data, path)=> {
    const keys = path.split('.');
    let currentValue = data;
    for (const key of keys) {
      if (currentValue && typeof currentValue === 'object') {
        if (Array.isArray(currentValue)) {
          //if currentValue is an array then 'key' will be index number
          const index = parseInt(key);
          currentValue = currentValue[index];
        } else {
          currentValue = currentValue[key];
        }
      } else {
        currentValue = null;
        break;
      }
    }
    return currentValue;
  }

/**
 * Method used to return edited value in uischema's option of a field
 * @param uischema 
 * @returns 
 */
export const getEditedValueFromOption = (uischema: any)  => { 
    return uischema.options?.edited;
} 