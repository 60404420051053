import React from 'react';
import strings from '../../localizations/homeScreen';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import CQToast from '../CQToast/CQToast';
import CQInProgressTab from '../CQInProgressTab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Title: {
      color: '#0070D2',
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "1rem"
    },
    toastContainer: {
      position: 'absolute',
      '& button': {
        display: 'none'
      },
      '& .slds-notify_toast': {
        left: '0%',
        position: 'absolute',
        marginTop: '-3.5rem',
        width: '100%',
        '@media (max-width: 992px)': {
          width: '90vw',
          padding: '0.2rem',
        },
        '@media (max-width: 576px)': {
          marginLeft: '0px',
          minWidth: '10rem !important',
          width: '100%',
          '& h2': {
            fontSize: '0.75rem !important',
            textAlign: 'justify !important',
            margin: '0px 10px !important',
          }
        }
      }
    }
  }),
);

export default function CQSubmittedList(props) {
    const classes = useStyles();

    return (
      <React.Fragment>
        {Object.keys(props!.item).length > 0 ?
        <React.Fragment>
            <CQToast
                variant="info"
                heading={strings.formSubmissionMessage}
                duration={0}
                className={classes.toastContainer}
            />
        <div className="slds-box slds-grid slds-text-body_regular slds-m-horizontal_small" style={{marginTop: '3.5rem'}}>
          <div className="slds-col slds-grid slds-size_12-of-12 slds-wrap slds-p-vertical_xxx-small">
            <CQInProgressTab item={props.item} totalItems={4} />
          </div>
        </div>
        </React.Fragment>: ""}
      </React.Fragment>
    )
}