import { CQAppConstant } from 'app-constants';
import CQFormBuilderSF from './CQFormBuilderSF';
import {CQFormBuilderConstants} from './formbuilder-constants';
/**
 * Represents a form manipulation logic
 */
export class CQFormLayout {

    uischema : any = [];
    dropZonesMap : Map<string, any>;
    controlsMap : Map<number, any>;
    controlIdWithDropzoneIdMap : Map<number, any>;
    fieldApiNameAndControlIdMap : Map<string, number>;
    dropIndex : number = 1;
    controlIndex : number = 100;
    isDocumentType : boolean = false;

    public constructor(uischema : any = {}) {
        this.uischema = this.setUISchema(uischema);
        this.dropZonesMap = new Map<string, any>();
        this.controlsMap = new Map<number, any>();
        this.controlIdWithDropzoneIdMap = new Map<number, any>();
        this.fieldApiNameAndControlIdMap = new Map<string, number>();
        this.processUISchema(this.uischema);
    }

    public setUISchema(uischema){
        if(this.isDocumentUIschema(uischema)){
            this.isDocumentType = true;
            return this.processSchema(uischema);
        }else{
            let temp : any = [];
            if(Array.isArray(uischema)){
                temp = uischema;
            }else if(uischema.hasOwnProperty('type') && !uischema.hasOwnProperty('sectionId') ){
                 temp = this.processSchema(uischema);;
            }else {
                temp = [...temp, uischema];
            }
            return temp;
        }
    }

    /**
     * Checks if uischema contains documents or not
     * @param uischema 
     * @returns 
     */
    public isDocumentUIschema(uischema){
        return JSON.stringify(uischema).includes('Questions') && JSON.stringify(uischema).includes('cqext__SQX_Safety_Inspection__c') && JSON.stringify(uischema).includes('cqext__SQX_Safety_Inspection_Criteria__c')
    }

    public isDocumentUIPerSectionProcessed(uischema){
        return JSON.stringify(uischema).includes('Questions') && JSON.stringify(uischema).includes('cqext__SQX_Safety_Inspection__c') && JSON.stringify(uischema).includes('cqext__SQX_Safety_Inspection_Criteria__c') && JSON.stringify(uischema).includes('sectionId')
    }

    public processSchema(uischema : any){
        //checking if uischema has been already processed of not
        if(this.isDocumentUIPerSectionProcessed(uischema)){
            if(uischema.hasOwnProperty('elements')){
                for(let index = 0 ; index < uischema['elements'].length ; index++){
                    if(this.isDocumentUIPerSectionProcessed(uischema['elements'][index])){
                        break;
                    }else{
                        let sectionUIschema : any = { 'type' : 'VerticalLayout' , 'elements' :[]};
                        uischema['sectionId'] = '1';
                        sectionUIschema.elements.push(uischema);
                    }
                }
            }
            return uischema;
        }
        else if(!uischema.hasOwnProperty('sectionId') && uischema.hasOwnProperty('elements')){
            let sectionUIschema : any = { 'type' : 'VerticalLayout' , 'elements' :[]};
            uischema['sectionId'] = '1';
            sectionUIschema.elements.push(uischema);
            return sectionUIschema;
        }
    }

    /**
     * This method process different structure of uischema
     * @param uischema 
     */
    public processUISchema (uischema) {
        if(Array.isArray(uischema)){
            for(let index = 0; index < uischema.length ; index++){
                if(uischema[index].hasOwnProperty('type') && uischema[index].type === 'VerticalLayout'){
                    this.processUISchema(uischema[index].elements);
                }else if (uischema[index].hasOwnProperty('type') && uischema[index].type === 'Group'){
                    for(let groupItemIndex = 0 ; groupItemIndex < uischema[index].elements.length ; groupItemIndex++){
                        this.initializeEditorIds(uischema[index].elements[groupItemIndex], uischema[index]);
                    }
                }
            }
        }else{
            this.initializeEditorIds(uischema);
        }
    }

    /**
     * Returns uischema 
     * @param index 
     * @returns 
     */
    public getUiSchema(index : number)  {
        let sectionUISchema: any;
        if(Array.isArray(this.uischema)){    
            sectionUISchema = this.uischema[index];

        }else{
            sectionUISchema = this.uischema.elements[index];
        }
        return sectionUISchema;
    }

    public isSourceFormField(dropId : string) : Boolean {
        return dropId.indexOf('drop-') === 0;
    }

    public isGeneralFieldPanel(dropId: string) : Boolean {
        return dropId === 'GeneralField';
    }

    public isObjectFieldPanel(dropId : string) : Boolean {
        return dropId === 'ObjectField';
    }

    public isContextObjectFieldPanel(dropId : string) : Boolean {
        return dropId === 'ContextObjectField';
    }

    public addSectionInUISchema(){
        let sectionUISchema : any = {
            "type": "VerticalLayout",
            'sectionId': '$sectionIndex',
            "elements": [
                {
                    "label": "Section Header Title Area",
                    "type": "Group",
                    "elements": [
                        {
                            "type": "HorizontalLayout",
                            "elements": [
                            ]
                        }
                    ]
                }
            ]
        }
        if(this.isDocumentUIschema(this.uischema) || this.isDocumentType){
            sectionUISchema = JSON.parse(JSON.stringify(Object.assign({}, CQFormBuilderConstants.safetyInspectionUItemplate)));
            /**for safety document only in v 14.0.0 : need to support for all object */
            this.processSchema(sectionUISchema);
            let sectionIndex = this.uischema.elements.length;
            sectionUISchema.sectionId = this.uischema.elements.length > 0 ? String(Number(this.uischema.elements[sectionIndex - 1].sectionId)+1) : '1';
            sectionUISchema.elements.map((items)=>{
                if(items.hasOwnProperty('layout')){
                        items.section= this.uischema.elements.length + 1;
                    }
            })
            this.processUISchema(sectionUISchema);
            this.uischema.elements.push(sectionUISchema);
        }else{
            if(!this.uischema.hasOwnProperty('sectionId') && this.uischema.type === 'VerticalLayout'){
                sectionUISchema = JSON.parse(JSON.stringify(sectionUISchema).replace('$sectionIndex', this.uischema.elements.length + 1));
                this.uischema.elements.push(sectionUISchema)
                this.processUISchema(this.uischema);
            }else{
                sectionUISchema = JSON.parse(JSON.stringify(sectionUISchema).replace('$sectionIndex', this.uischema.length + 1));
                this.uischema = [...this.uischema, sectionUISchema];
                this.processUISchema(this.uischema);
            }
        }
    }


    public moveField(droppedField: string,sourceDropZoneId: string, dropZoneId : string) {
        // identify the source drop zone and destination drop zone
        let [sourceDropZone, sourceIndex] = this.getDropZone(sourceDropZoneId);
        let [destinationDropZone, destinationIndex] = this.getDropZone(dropZoneId);

        if(sourceDropZone && destinationDropZone) {
            
            let sourceElement : any;
            let destinationElement: any;
            
            // since we are doing inplace swap if swap is occuring on same array higher index is removed
            // first. This will ensure that both index remains valid.
            if(sourceIndex > destinationIndex) {
                [sourceElement] = sourceDropZone.removeElementAtIndex(sourceIndex);
                [destinationElement] = destinationDropZone.removeElementAtIndex(destinationIndex);

                
                destinationDropZone.addElement(sourceElement, destinationIndex,  ()=> {});
                sourceDropZone.addElement(destinationElement, sourceIndex, ()=> {});
            } else {
                [destinationElement] = destinationDropZone.removeElementAtIndex(destinationIndex);
                [sourceElement] = sourceDropZone.removeElementAtIndex(sourceIndex);

                
                sourceDropZone.addElement(destinationElement, sourceIndex, ()=> {});
                destinationDropZone.addElement(sourceElement, destinationIndex,  ()=> {});
            }

        }
    }

    private getDropZone(dropZoneIdWithIndex : string) {
        let pattern = /^(?<dropzoneId>drop-\d+)(?:-(?<elementIndex>\d+))$/;
        let match = dropZoneIdWithIndex.match(pattern);

        if(match && match.groups) {
            let dropZoneId = match.groups['dropzoneId'];
            let index = parseInt(match.groups['elementIndex']);
            let dropZone = this.dropZonesMap.get(dropZoneId);
            return [dropZone, index];
        }
        return [null, null];
    }

    public addGeneralField(droppedField: string, dropZoneIdWithIndex: string, uischemaObj:  any) {
        this.addFieldInternal(droppedField, dropZoneIdWithIndex, "#/properties/General/properties/" + droppedField, uischemaObj);
    }

    public addField(droppedField: string, dropZoneIdWithIndex:string, droppedFieldUiSchema: object, selectedObjectApiName:string, contextObject?:any, dependentSchema?:any){
        // check if field added is for main object or context object to update uischema accordingly
        if(contextObject) {
            this.addFieldInternal(droppedField, dropZoneIdWithIndex, `#/properties/${selectedObjectApiName}/properties/${contextObject.contextObjectRelationshipName}/properties/` + droppedField, droppedFieldUiSchema);
        } else {
            let dependentUIschema;
            if(dependentSchema) { 
                dependentUIschema = {
                    'type': 'dependent',
                    'options': { 'showLabel' : true},
                    "controllingfield": `${selectedObjectApiName}/${dependentSchema.dependent}`
                }
            }
            this.addFieldInternal(droppedField, dropZoneIdWithIndex, `#/properties/${selectedObjectApiName}/properties/` + droppedField, droppedFieldUiSchema, dependentUIschema);
        }
    }

    private addFieldInternal(droppedField: string, dropZoneIdWithIndex:string, scope: string, droppedFieldUiSchema: Object, dependentUIschema?:any) {
        // identify the drop zone
        let [dropZone, index] = this.getDropZone(dropZoneIdWithIndex);
        if(dropZone !== null) {
            let uischemaObj : any = {
                "type": "Control",
                "scope": scope
            };
            if(Object.keys(droppedFieldUiSchema).length && !dependentUIschema){
                uischemaObj = droppedFieldUiSchema;
                uischemaObj['scope'] = scope;
            }else{
                uischemaObj = dependentUIschema? dependentUIschema : uischemaObj;
                uischemaObj['scope'] = scope;
            }
            // add to schema too
            dropZone.addElement(this.assignControlId(uischemaObj), index, (overflowElement) => {
                this.onSectionOverflow(overflowElement, dropZone);
            });
            console.log(dropZone);
        } else {
            console.error('Couldnt find dropzone with value ' + dropZoneIdWithIndex);
        }
    }

    /**
     * This method is used to remove a specific field in uischema
     * @param dropZoneIdWithIndex 
     */
    public removeField(dropZoneIdWithIndex:string) {
        let [dropZone, index] = this.getDropZone(dropZoneIdWithIndex);
        if(dropZone !== null) {
            let [sourceDropZone, sourceIndex] = this.getDropZone(dropZoneIdWithIndex);
            dropZone.removeElementAtIndexAndEmptyLayout(sourceIndex);
        }
    }

    private onSectionOverflow(overflowElement: any, dropZone: CQHorizontalSection) {
        let parentSchema = dropZone.parentSchema;
        let indexOfCurrentDropZone = parentSchema.elements.indexOf(dropZone.uiSchema);
        let sibling : any = parentSchema.elements[indexOfCurrentDropZone + 1];
        if(sibling && sibling.type === 'HorizontalLayout') {
            sibling = this.dropZonesMap.get(sibling.options.id);
        }
        if(sibling && sibling.hasSpace && sibling.hasSpace()) {
            sibling.addElement(overflowElement, 0, (overflowElement) => {
                this.onSectionOverflow(overflowElement, sibling);
            });
        } else {
            let newSection = new CQHorizontalSection({
                "type": "HorizontalLayout",
                "elements": []
            }, dropZone.parentSchema);
            this.assignDropZoneId(newSection);
            newSection.addElement(overflowElement, 0, () => {
                //shouldn't be ocurring
            });
            parentSchema.elements.splice(indexOfCurrentDropZone + 1, 0, newSection.uiSchema);
        }
    }

    public refresh(): CQFormLayout {
        if(!this.uischema.hasOwnProperty('type') && this.uischema.hasOwnProperty('elements')){
            this.uischema = { "type" : "VerticalLayout", "elements" : this.uischema.elements};
            return new CQFormLayout(JSON.parse(JSON.stringify(this.uischema)))
        }
        return new CQFormLayout(JSON.parse(JSON.stringify(this.uischema)));
    }


    /**
     * This method is used to rearrange/assign dropzone id, control id(id of each dragged element) and set dropzoneId with index of element to control id for each element that dragged/moved in ui
     * @param uischema 
     * @param parent 
     * @param index 
     */
    private initializeEditorIds(uischema, parent?: any, index?:any) {
        if(uischema.type === 'HorizontalLayout') {
            this.assignDropZoneId(new CQHorizontalSection(uischema, parent));
        } else if(uischema.type === 'Control' || uischema.type === 'select' || uischema.type === "lookup" || uischema.type === 'blank' || uischema.type === 'dependent') {
            this.assignControlId(uischema);
            this.assignControlIdAndDropzoneId(uischema, parent.options.id+'-'+index);
        } else {
            // ignore other types as we don't really worry about them
        }

        if(uischema.elements) {
            uischema.elements.forEach((childUiSchema,index) => {
                this.initializeEditorIds(childUiSchema, uischema, index);
            })
        }
    }

    private assignControlId(control) {
        control.options = control.options || {};
        control.options.id = this.controlIndex;
        this.controlIndex += 100;
        this.controlsMap.set(control.options.id, control);
        this.fieldApiNameAndControlIdMap.set(control.scope.split("/").pop(), control.options.id);
        return control;
    }

    private assignDropZoneId(dropZone: LayoutSection) {
        dropZone.setId(this.dropIndex);
        this.dropIndex++;
        this.dropZonesMap.set(dropZone.getId(), dropZone);
    }

    /**
     * This method is used to set dropezone id with position(index) of dragged field in layout elements to control index(id of dragged field)
     * @param control 
     * @param dropzoneIdWithIndex 
     */
    private assignControlIdAndDropzoneId(control, dropzoneIdWithIndex){
        // this map contains key as controlIndex and value as dropzoneid with field position(index)
        this.controlIdWithDropzoneIdMap.set(control.options.id, dropzoneIdWithIndex);
    }

}

interface LayoutSection {
    addElement(newElement: any, index: number, onSectionOverflowed : (overflowElement: any) => void);
    setId(id: number);
    getId(): string;
}


class CQHorizontalSection implements LayoutSection {
    defaultMaxItems = 2;

    uiSchema: any;
    parentSchema: any;

    constructor(uiSchema: any, parentSchema : any) {
        this.uiSchema = uiSchema;
        this.parentSchema = parentSchema;
    }

    setId(id: number) {
        let options = this.uiSchema.options || {};
        options.id = 'drop-' + id;
        this.uiSchema.options = options;
    }

    getId() : string{
        return this.uiSchema.options?.id;
    }

    addElement(newElement : any, index: number, onSectionOverflowed) {
        let elements = this.uiSchema.elements;
        if(this.hasSpace()) {
            if(elements.length){
                elements.splice(index+1, 0, newElement);
            }else{
                elements.splice(index, 0, newElement);
            }
        } else {
            // add to ui schema elements
            onSectionOverflowed(newElement);
        } 
    }

    hasSpace() : boolean {
        let maxElementCount = this.uiSchema.options?.columns || this.defaultMaxItems;
        return this.uiSchema.elements.length < maxElementCount;
    }

    removeElementAtIndex(index): any {
        return this.uiSchema.elements.splice(index, 1);
    }

    /**
     * This method is used to remove field using index and also remove the empty layout in uischema, which left back after removing the fields in a row.
     * @param index 
     * @returns removed element from uischema
     */
    removeElementAtIndexAndEmptyLayout(index) : any {
        let element : any = this.uiSchema.elements.splice(index, 1);
        if(!this.uiSchema.elements.length){
            this.parentSchema.elements.forEach((element, Elementindex) => {
                if(!element.elements.length && this.parentSchema.elements.length > 1){
                    this.parentSchema.elements.splice(Elementindex, 1);
                }
            });
        }
        return element;
    }

}

export default CQFormLayout;