import React, { useState, useEffect } from "react";
import Modal from "@salesforce/design-system-react/components/modal";
import formBuilderScreenStrings from "localizations/formBuilderScreen";

const CQFormBuilderTableModal = ({
  openModal,
  closeModal,
  title,
  headerValue,
  fieldApiName,
  relationalAPIName,
  handleUpdateHeader,
  validations,
  handleSave,
  isColumnModal
}) => {

  const [fieldApiNameInModal, setFieldApiNameInModal] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [relnAPIName, setRelnAPIName ] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [validationRuleCode, setValidationRuleCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isNoneSelected, setIsNoneSelected] = useState(false);
  
  useEffect(() => {
    setFieldApiNameInModal(fieldApiName);
    setInputValue(headerValue);
    setSelectedOption(validations.regexRuleName);
    setValidationRuleCode(validations.pattern);
    setErrorMessage(validations.errorMessage);
    
    if(validations.regexRuleName === 'none'){
      setIsNoneSelected(true);
    }else{
      setIsNoneSelected(false);
    }
  }, [validations,title])

  useEffect(() => {
    setRelnAPIName(relationalAPIName);
  },[relationalAPIName])

  // style used for section of modal appears when editing field
  const modalSectionStyle = {
    padding: "1rem",
  };

  // style used for modal text input
  const modalInputStyle = {
    width: "100%",
  };

  // style used for modal footer section
  const modalFooterStyle = {
    display: "flex",
    justifyContent: "flex-end",
  };

  /**
   * Method used to set unmapped field api name when label is changed
   * @param eve 
   */
  const handleBlurApiNameChange = (eve : any) => {
    let newFieldAPIName = 'CQ_Form_'+eve.target.value.trim().replace(/[\s\W]+/g, '_')
    if(!fieldApiName || fieldApiName !== newFieldAPIName){
      setFieldApiNameInModal(newFieldAPIName);
    }
  }

  const handleRelationalAPIName = (evt : any) => {
    setRelnAPIName(evt.target.value);
  }

  /**
   * Method used to set api name when api name is manually changed in modal
   * @param eve 
   */
  const handleApiNameChange = (eve : any) => {
    setFieldApiNameInModal(eve.target.value);
  }
  const handleClose =() => {
    setFieldApiNameInModal("");
    closeModal();
  }
  // Handle input change
  const handleInputChange = (eve:any) => {
    setInputValue(eve.target.value);
    handleUpdateHeader(eve);
    };

  const patterns = {
    default: {
      regex: '',
      regexRuleName: 'Select an option',
      message: ''
    },
    "none": {
      regex: '',
      regexRuleName: "None",
      message: ""
    },
    "Email Only": {
      regex: "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$",
      regexRuleName: "Email Address",
      message: "Field Validation : Email Address Only"
    },
    "Phone Number Only": {
      regex: "^\\+?[1-9]\\d{1,14}$",
      regexRuleName: "Phone Number",
      message: "Field Validation : Phone Number Only"
    },
    "Numeric Only": {
      regex: "^\\d+$",
      regexRuleName: "Numeric",
      message: "Field Validation : Numeric Only",
    },
    "Alphanumeric Only": {
      regex: "^[a-zA-Z0-9]+$",
      regexRuleName: "Alphanumeric",
      message: "Field Validation : Alphanumeric Only",
    },
    "Alphabetic Only": {
      regex: "^[a-zA-Z]+$",
      regexRuleName: "Alphabetic",
      message: "Field Validation : Alphabetic Only",
    },
    "ZIP Code Only": {
      regex: "^\\d{5}(?:-\\d{4})?$",
      regexRuleName: "ZIP Code",
      message: "Field Validation : ZIP Code Only",
    },
    "URL Only": {
      regex: "^(https?:\\/\\/)?(www\\.)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(\\/.*)?$",
      regexRuleName: "URL",
      message: "Field Validation : URL Only",
    },
    "Custom (Enter Below)": {
      regex: '',
      regexRuleName: "Custom (Enter Below)",
      message: ""
    }
  }
 
  // Function to handle pattern selection
  const handlePatternSelection = (e) => {
    const selectedPatternName = e.target.value;
    const pattern = patterns[selectedPatternName];
    setSelectedOption(selectedPatternName);
    setValidationRuleCode(pattern.regex);
    setErrorMessage(pattern.message);
    if(selectedPatternName === 'none'){
      setIsNoneSelected(true);
    }else{
      setIsNoneSelected(false);
    }
  };

  const handleValidationRuleCodeChange = (e) => {
    setValidationRuleCode(e.target.value); // Update validationRuleCode with the user's input
  };

  const handleErrorMessageChange = (e) => {
    setErrorMessage(e.target.value); 
  }

  return (
    <Modal
      dismissOnClickOutside={false}
      isOpen={openModal}
      onRequestClose={handleClose}
      title={title}
      ariaHideApp={false}
    >
      <section className="slds-p-around_medium">
        <div className="slds-gutters" style={modalSectionStyle}>
          <p className="slds-text-heading_small slds-m-bottom_x-small">
            {isColumnModal ?formBuilderScreenStrings.columnTitle :  formBuilderScreenStrings.headerTitle}
          </p>
          <input
            placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
            className="slds-p-around_x-small"
            style={modalInputStyle}
            value={headerValue}
            onChange={handleInputChange}
            onBlur={(e) => handleBlurApiNameChange(e)}
          />
          <p className="slds-text-heading_small slds-m-bottom_x-small">
            {formBuilderScreenStrings.apiName}
          </p>
          <input
            placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
            className="slds-p-around_x-small"
            style={modalInputStyle}
            value={fieldApiNameInModal ? fieldApiNameInModal: fieldApiName}
            onChange={(e) => {
              handleApiNameChange(e);
            }}
          />
          {isColumnModal ? <>
            <br/>
            <div>
            <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">
              {formBuilderScreenStrings.validationRule}
            </p>
            <select className="slds-select slds-p-horizontal_x-small" onChange={handlePatternSelection} defaultValue={selectedOption}>
              {Object.keys(patterns).map((patternName) => (
                <option key={patternName} value={patternName ? patternName : ""}>
                  {patterns[patternName].regexRuleName}
                </option>
              ))}
            </select>
            </div>
            <br />
            <div>
              <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">{formBuilderScreenStrings.validationRuleCode}</p>
              <input 
                className="slds-textarea" 
                value={selectedOption ? validationRuleCode : ""} 
                onChange={handleValidationRuleCodeChange}
                disabled={isNoneSelected}
              />
            </div>
            <div>
              <p className="slds-text-heading_small slds-m-bottom_x-small slds-m-top_x-small">{formBuilderScreenStrings.validationErrorMessage}</p>
              <input 
                className="slds-input" 
                value={selectedOption ? errorMessage : ""} 
                onChange={handleErrorMessageChange} 
                disabled={isNoneSelected}
              />
            </div>
          </>: null}
          {
            !isColumnModal ?
            <>
              <p className="slds-text-heading_small slds-m-bottom_x-small">
                {formBuilderScreenStrings.relnAPIName}
              </p>
              <input
                placeholder={formBuilderScreenStrings.generalFieldPlaceholder}
                className="slds-p-around_x-small"
                style={modalInputStyle}
                value={relnAPIName}
                onChange={(e) => {
                  handleRelationalAPIName(e);
                }}
                />
            </>
            : null
          }
          <div className="slds-grid slds-m-top_large" style={modalFooterStyle}>
            <button
              className="slds-button slds-button_neutral"
              onClick={handleClose}
            >
              {formBuilderScreenStrings.cancel}
            </button>
            <button
              className="slds-button slds-button_brand"
              onClick={() => isColumnModal ? handleSave(fieldApiNameInModal, inputValue , selectedOption , validationRuleCode , errorMessage) : handleSave(fieldApiNameInModal, relnAPIName)}
            >
              {formBuilderScreenStrings.save}
            </button>
          </div>
        </div>
      </section>
    </Modal>
  );
};
 
export default CQFormBuilderTableModal;