import SFAPI from 'api/sfapi';
import IAccessManager from 'api/IAccessManager';
import StubAccessManager from 'api/StubAccessManager';
import { fillInValues, transformDataToClassicFormat } from 'services/data-mapping.service';
import { CQFormBuilderConstants } from './formbuilder-constants';


export class CQFormJSONProcessor{

    uischema : any;
    deserializedFormJSON : any;
    formSavedJSON : Boolean;
    selectedDocsIds : [];
    data : any;
    formBuilderData:any;
    lookupCacheData : [];
    queries : any;
    draggedItems: any[];
    isFileAttachmentAdded: boolean;
    startingDataCache : any;
    formBuilderGridPerSection : [];
    docIdAndStartingDataCacheMap :  Map<string, any>;
    docIdAndLookupCacheMap :  Map<string, any>;
    readOnlyFieldsList :  [];

    public static GENERAL = 'General';

    sfAPI : SFAPI;
    accessManager: IAccessManager = new StubAccessManager();

    public constructor(accessManager ?: IAccessManager){
        this.sfAPI = new SFAPI();
        if(accessManager !== undefined){
            this.setAccessManager(accessManager)
        }
        this.data = {};
        this.formBuilderData = {};
        this.formBuilderGridPerSection = [];
        this.formSavedJSON = false;
        this.isFileAttachmentAdded = false;
        this.docIdAndStartingDataCacheMap = new Map<string, any>();
        this.docIdAndLookupCacheMap = new Map<string, any>();
        this.readOnlyFieldsList = [];
        this.selectedDocsIds = [];  
        this.lookupCacheData = [];  
        this.draggedItems = [];  
    }

    /**
     * 
     * @param recordId 
     * @param uiSchema 
     * @param lookupCacheString 
     */
    public async getFormJSON (recordId, uiSchema, lookupCacheString){
        try {
            this.queries = [];
            let startingDataCache: any = [];
            let data : any = {};

            let currentFormData = await this.sfAPI.getSavedFormJSON(recordId);
            this.formSavedJSON = window.atob(currentFormData.formJSON).includes("schema");
            if(this.formSavedJSON){
                
                //setCurrentFormData(currentFormData);
                let currentFormBuilderSavedJSON : any = JSON.parse(window.atob(currentFormData["formJSON"]));
                this.deserializedFormJSON = currentFormBuilderSavedJSON;
                //setSelectedObjectApiName(currentFormBuilderSavedJSON.cqFormType);
                if(currentFormBuilderSavedJSON.cqFormType.includes('SQX_Safety_Inspection__c')){
                    this.uischema = CQFormBuilderConstants.safetyInspectionUiSchema;
                }

                // set readOnly fields
                this.readOnlyFieldsList = currentFormBuilderSavedJSON.readOnly;

                // set SIC docIds that were in use in Form JSON
                this.selectedDocsIds = currentFormData.selectedSICDocIds;

                //set dragged fields from schema
                let draggedFieldItems : any = [];
                if(currentFormBuilderSavedJSON && currentFormBuilderSavedJSON.schema && currentFormBuilderSavedJSON.schema.properties) {
                    for (var key in currentFormBuilderSavedJSON.schema.properties) {
                        var obj = currentFormBuilderSavedJSON.schema.properties[key].properties;
                        for (var fieldprop of Object.keys(obj)) {
                            if (!fieldprop.includes('__r')) {
                                draggedFieldItems.push(fieldprop);
                            }else if(fieldprop.includes('__r') && fieldprop === currentFormBuilderSavedJSON.contextObjectRelationshipName){
                                const definitions : object = currentFormBuilderSavedJSON.schema.definitions
                                for (const definitionName in definitions) {
                                    const properties = definitions[definitionName].properties;
                                    for(var relatedFieldProp of Object.keys(properties)){
                                        draggedFieldItems.push(currentFormBuilderSavedJSON.contextObjectRelationshipName+'.'+relatedFieldProp);
                                    }
                                }
                            }
                        }
                    }
                }
                this.draggedItems = draggedFieldItems;

                //set uischema for fields to show
                if(currentFormBuilderSavedJSON && currentFormBuilderSavedJSON.ui && currentFormBuilderSavedJSON.ui.elements) {
                    let uischemaElements :any = [];
                    currentFormBuilderSavedJSON.ui.elements.forEach((item,idx) => {
                        if(item.label !== "Signatures"){
                            uischemaElements.push(item);
                        }
                    });
                    uiSchema.uischema["elements"]= uischemaElements;
                    this.uischema = uiSchema.refresh();
                }

                // Check if form definition has file attachment and load it on reediting
                if (currentFormBuilderSavedJSON && currentFormBuilderSavedJSON.Files) {
                    this.isFileAttachmentAdded = true;
                }
                
                // set data, lookupCacheData and startingData in saved Form JSON
                if (currentFormBuilderSavedJSON && currentFormBuilderSavedJSON.queries && currentFormBuilderSavedJSON.queries.length) {
                    let addedSections = currentFormBuilderSavedJSON.queries;
                    let tempData : any = JSON.parse(JSON.stringify(data)) || {};
                    let currentLookupCacheData : any = this.lookupCacheData;
                    let startingDataMap : any =  new Map();
                    let lookupCacheDataMap : any = new Map();
                    for(let i=0;i<addedSections.length; i++){
                        addedSections[i]["key"] = addedSections[i].filter.s_value;
                        addedSections[i]["type"] = "DOCUMENTS";
                        this.queries.push(addedSections[i]);
                        let response = await this.sfAPI.getStartingData(recordId, JSON.stringify(addedSections[i]), lookupCacheString);
                        startingDataMap.set(addedSections[i].filter.s_value, response.startingData);
                        lookupCacheDataMap.set(addedSections[i].filter.s_value, response.lookupCacheData);
                        startingDataCache[i] = response.startingData;
                        let returnObj :any = this.getDataLookupCacheAndStartingDataAfterProcessing(response.startingData, response.lookupCacheData, currentLookupCacheData, tempData);
                        tempData = returnObj['tempData'];
                        currentLookupCacheData = returnObj['lookupCacheDataTobeUpdated'];
                        this.startingDataCache = JSON.parse(JSON.stringify(startingDataCache));
                    }
                    if(currentFormBuilderSavedJSON.hasOwnProperty('formBuilderData')){
                        this.data =  {...tempData, ...currentFormBuilderSavedJSON.formBuilderData};
                    }else{
                        this.data = tempData;
                    }
                    this.lookupCacheData = currentLookupCacheData;
                    this.docIdAndStartingDataCacheMap = startingDataMap;
                    this.docIdAndLookupCacheMap = lookupCacheDataMap;
                } else {
                    if(currentFormBuilderSavedJSON.hasOwnProperty('formBuilderData')){
                        this.data =  currentFormBuilderSavedJSON.formBuilderData;
                    }
                    if(currentFormBuilderSavedJSON.hasOwnProperty('formBuilderGridPerSection')){
                        this.formBuilderGridPerSection = currentFormBuilderSavedJSON.formBuilderGridPerSection;
                    }
                }

            }
        }catch (e) {
            if (e.response && e.response.data) {
                //setErrors(homeScreenStrings.serverError + JSON.stringify(e.response.data));
            } else {
                if (e.message === 'Network Error') {
                   // setErrors(strings.networkError);
                } else {
                   // setErrors(JSON.stringify(e.message));
                }
            }
        }
    }

    /**
     * This method used to process lookupCacheData for resultTypeValues and data that we use in JSONForms
     * @param startingData 
     * @param lookupCache 
     * @param lookupCacheDataList 
     * @param tempData 
     * @returns data and lookupCacheData
     */
    public getDataLookupCacheAndStartingDataAfterProcessing(startingData : any, lookupCache : any,lookupCacheDataList : any, tempData: any){
        let lookupCacheDataTobeUpdated : any = {};
        if(Object.keys(lookupCacheDataList).length){
            lookupCacheDataTobeUpdated[(Object.keys(lookupCache)[0])] = [];
            lookupCacheDataTobeUpdated[(Object.keys(lookupCache)[0])].push(...lookupCacheDataList[(Object.keys(lookupCache)[0])]);
            for(let j=0; j<lookupCache[(Object.keys(lookupCache)[0])].length ; j++){
                let responseLookupCache : any = lookupCache[(Object.keys(lookupCache)[0])][j];                    
                let isExisted : boolean = this.checkLookupCacheDataExistance(responseLookupCache, lookupCacheDataList[(Object.keys(lookupCache)[0])])
                if(!isExisted){
                    lookupCacheDataTobeUpdated[(Object.keys(lookupCache)[0])].push(responseLookupCache);
                }
            }
        }else{
            lookupCacheDataTobeUpdated = lookupCache;
        }
        if(startingData){
            startingData?.forEach((record, index)=>{
                if(this.queries){
                    let mapping = this.queries[index].mappingData;
                    if (mapping && record.recordTrees ) {
                        record.recordTrees.forEach( (startingDatum) => {
                            let transformedData = transformDataToClassicFormat(startingDatum);
                            fillInValues(tempData, transformedData, mapping);
                        });
                    }
                }
            });
        }
        let returnObjWithTempDataAndLookupCacheData : object = {};
        returnObjWithTempDataAndLookupCacheData['tempData'] = tempData;
        returnObjWithTempDataAndLookupCacheData['lookupCacheDataTobeUpdated'] = lookupCacheDataTobeUpdated;

        return returnObjWithTempDataAndLookupCacheData
    }

    /**
     * This method used to check whether the lookupcachedata is already existed in the current list 
     * @param obj is the current lokupCacheData(resultTypeValues) of dragged document
     * @param list is the entire list which consists resultypeValues that are in use for already dragged documents
     * @returns true when current/incomming resultType already exists in the list
     */
    public checkLookupCacheDataExistance (obj:any, list: any){
        for (let i = 0; i < list.length; i++) {
            if (list[i]['Id'] === obj['Id']) {
                return true;
            }
        }
        return false;
    }

    public setAccessManager(accessManager : IAccessManager) : CQFormJSONProcessor {
        this.accessManager = accessManager;
        this.sfAPI.setAccessManager(accessManager);
        return this;
    }

    /**
     * This method call the get the meta data of the api name provided and return the label of the api name provided
     * @param apiName is the api name of the object that we want to get the label of
     */
    public async getLabelByAPIName(apiName: string) {
        const metadata :any = await this.sfAPI.getSeletectedObjectMetadata(apiName);
        return metadata.label;
    }

}