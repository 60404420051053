import axios from 'axios';
import { uuidv4 } from '../services/cqIdGenerator';
import { storeInDB, storeCachedImagesInDB, getCachedImageFromId, updateDBItem, getFilesItemInDB, deleteDBImagesForSubmission, deleteCachedImageFromId } from './imagedbapi';
import { CQSubmissionManager } from './CQSubmissionManager';
import IAccessManager from "./IAccessManager";
import StubAccessManager from './StubAccessManager';
import SFAPI from './sfapi';
import { CQAppConstant } from 'app-constants';

//constants
const NOT_STARTED : number = -1;
const PAUSED : number = -1;
const SYNCED : string = 'Synced';

/*
 * Class for managing file uploads to salesforce
 */
export class CQFileUploadManager{

    fileInfos : FileInfo [] = [];
    submissionId : string = '';
    fileSyncIntervalId : number = NOT_STARTED;
    accessManager : IAccessManager = new StubAccessManager();
    submissionManager : CQSubmissionManager = new CQSubmissionManager();
    sfApi : SFAPI = new SFAPI();
    
    constructor(fileInfos: FileInfo[] = [], startImmediately: boolean = false, submissionManager ?: CQSubmissionManager, accessManager ?: IAccessManager){
        if(submissionManager !== undefined) {
            this.submissionManager = submissionManager as CQSubmissionManager;
        }

        if(accessManager !== undefined) {
            this.accessManager = accessManager as IAccessManager;
        }

        this.fileInfos = fileInfos;
        if(startImmediately === true) {
            this.syncFileInfosWithDB().then(() => {
                this.start();
            });

            //added event when network status changed to online
            // this event will start uploading the unsynced files from fileInfos when online
            // when network is online, file upload should process so this listener proceeds file upload again
            window.addEventListener('online', (e) => { 
                console.log('online');
                if(this.fileSyncIntervalId === PAUSED) {
                    this.start();
                }
            });

            //event addd when network status changed to offline
            // this event will stop file uploading process in background when network changes to offline
            // when network is offline, file upload should pause so this listener stops uploading
            window.addEventListener('offline', (e) => {
                console.log('oflline');
                if(this.fileSyncIntervalId !== PAUSED && this.fileSyncIntervalId !== NOT_STARTED) {
                    this.stop();
                    this.fileSyncIntervalId = PAUSED;
                }
            });
        }
    }

    public setAccessManager(accessManager : IAccessManager) : CQFileUploadManager {
        this.accessManager = accessManager;
        this.submissionManager.setAccessManager(accessManager);
        this.sfApi.setAccessManager(accessManager);

        return this;
    }

    /*
     * method to convert string to blob object of file
     */
    private dataURItoBlob(dataURI : string) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        var byteString = atob(dataURI.split(',')[1]);
    
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
    
    /*
     * method to subscribe the onprogress on fileinfo
     */
    subscribeToProgressOf(localId: string, evtHandler: Function) {
        for(const fileInfo of this.fileInfos) {
            if(fileInfo.LocalId === localId) {
                fileInfo.onProgress = evtHandler;
                break;
            }
        }
    }
    
    /*
     * method to unsubscribe the progress in fileinfo
     */
    unsubscribeFromProgressOf(localId: string) {
        for(const fileInfo of this.fileInfos) {
            if(fileInfo.LocalId === localId) {
                fileInfo.onProgress = null as unknown as Function;
                break;
            }
        }
    }

    /**
     * This method gets cached images from cachedImagesDB
     * @param id 
     * @param fileData 
     * @param path 
     * @param fileName 
     * @param submissionId 
     * @param organizationId 
     * @param ContentDocumentId 
     * @returns 
     */
    public storeCachedImagesInDB = async (id : string, fileData : File, path: string, fileName: string, submissionId: string, organizationId: string,  ContentDocumentId : String = '') => {
        return await storeCachedImagesInDB(id, fileData, path, fileName, submissionId, organizationId);
    }

    /**
     * This method gets image uisng id from cachedImagesDB
     * @param id 
     * @returns 
     */
    public getCachedImageFromId = async (id: string) => {
        return await getCachedImageFromId(id);
    }

    /**
     * This method deletes image uisng id from cachedImagesDB
     * @param id 
     * @returns 
     */
    public deleteCachedImageFromId = async (id: string) => {
        return await deleteCachedImageFromId(id);
    }
    
    /*
     * method to update when error in file upload
     */
    private async handleFileUploadErrors(ex, fileInfo: FileInfo){
        try{
            this.stop();
            fileInfo.status = 'Unsynced';
            if(ex.message === 'Network Error'){
                fileInfo.error = 'Network Error';
            }
            else{
                if(ex.response.status === 401) {
                    this.accessManager.clearAuthorization();
                }
                fileInfo.error = ex.message;
            }
            
            fileInfo.informListener({'status': 'error', 'errorMessage': ex.message});
            
            let isStored = await storeInDB(fileInfo.LocalId, fileInfo.file, fileInfo.path, fileInfo.file.name, fileInfo.submissionId, fileInfo.organizationId);
            if(!isStored){
                fileInfo.error = 'Database Error: File not stored in DB';
                fileInfo.informListener({'status': 'error', 'errorMessage': fileInfo.error});
            }
            fileInfo.isUploading = false;
            this.submissionManager.updateFilePathInSubmission(fileInfo.submissionId, fileInfo);
        }catch(ex){
            fileInfo.error = ex.message;
            fileInfo.informListener({'status': 'error', 'errorMessage': ex.message});
            this.submissionManager.updateFilePathInSubmission(fileInfo.submissionId, fileInfo);
            fileInfo.isUploading = false;
        }
    }

    /**
     * This method checks for orgnaization and returns upload url and upload path accordingly
     * @returns object of upload url and upload path
     */
    getFileUploadDetails = () => {
        let uploadPath:any;
        let networkId:any;
        let uploadURL:any = this.accessManager.getUserContextSync().url;
        
        // check if user is uploading file from internal org or external sites
        if(localStorage.getItem('userDetailsSF')) {
            const userDetails:any =  JSON.parse(localStorage.getItem('userDetailsSF') || '');
            if(userDetails['userType'] === 'POWER_PARTNER') {
                networkId = userDetails['networkId'];
                uploadPath = `/services/data/${CQAppConstant.API_VERSION}/connect/communities/${networkId}/files/users/me`;
                uploadURL = userDetails['siteUrl'];
            } else {
                uploadPath = `/services/data/${CQAppConstant.API_VERSION}/connect/files/users/me`;
            }
        } else {
            uploadPath = `/services/data/${CQAppConstant.API_VERSION}/connect/files/users/me`;
        }

        return {
            uploadPath,
            uploadURL
        }
    }

    /*
     * Method to start upload file 
     */
    startFileUpload = (file: File, path: string) : any => {
        if(this.sfApi.isAuthorized()){
            const organizationId = window.localStorage.getItem("organizationId");
            var fileInfo: FileInfo = new FileInfo(file, path, this.submissionId, organizationId);
            fileInfo.isUploading = true;
            try{
                // check if user is uploading file from internal org or external sites
                const {uploadPath, uploadURL} = this.getFileUploadDetails();

                //user contexts
                const headers = {
                    "Authorization" : `OAuth ${this.accessManager.getUserContextSync().token}`
                };

                const body = new FormData()
                body.append('fileData',file);
                axios.post(`${uploadURL}${uploadPath}`, body, {
                    headers: headers,
                    onUploadProgress: ((e) => {
                        const percent = Math.round((e.loaded * 100) / e.total);
                        fileInfo.progress =  percent;
                        fileInfo.informListener({'progress': percent});
                        
                    })
                }).then(async (response) => {
                    fileInfo.ContentDocumentId = response.data.id;
                    fileInfo.status = 'Synced';
    
                    fileInfo.informListener({'progress': 100, 'status': 'uploaded', ContentDocumentId: fileInfo.ContentDocumentId});
                    await this.submissionManager.updateFilePathInSubmission(fileInfo.submissionId, fileInfo);
                    
                    fileInfo.isUploading = false;
                }).catch(async (ex)=> {
                    await this.handleFileUploadErrors(ex, fileInfo);
                });
            }catch(ex){
                this.handleFileUploadErrors(ex, fileInfo);
            }finally{
                this.fileInfos.push(fileInfo);
                return fileInfo;
            }
        }else{
            throw Error("Authorization Failed. Please login again.");
        }
    }

    
    /*
     * method to upload specific file
     */
    uploadFileToSF =  (fileInfo: FileInfo) => {
        try{
            if(this.sfApi.isAuthorized()){
                if(!fileInfo.isUploading){
                    if(fileInfo.LocalId){
                        // check if user is uploading file from internal org or external sites
                        const {uploadPath, uploadURL} = this.getFileUploadDetails();

                        //user contexts
                        const headers = {
                            "Authorization" : `OAuth ${this.accessManager.getUserContextSync().token}`
                        };

                        const body = new FormData()
                        body.append('fileData', fileInfo.file);
                        fileInfo.isUploading = true;
                        axios.post(`${uploadURL}${uploadPath}`, body, {
                            headers: headers,
                            onUploadProgress: ((e) => {
                                const percent = Math.round((e.loaded * 100) / e.total);
                                fileInfo.progress =  percent;
                                fileInfo.informListener({'progress': percent});
                            })
                        }).then(async (response)=> {
                            var contentDocument = response.data;
                            if(contentDocument && contentDocument.id !== 'Unsynced'){
                                fileInfo.ContentDocumentId = contentDocument.id;
                                fileInfo.status = 'Synced';
                                fileInfo.error = '';
                                await updateDBItem(fileInfo.LocalId, contentDocument.id);
                                fileInfo.isUploading = false;
                                fileInfo.informListener({'progress': 100, 'status': 'uploaded', ContentDocumentId: fileInfo.ContentDocumentId});
                            }
                        }).catch((ex)=> {
                            this.stop();
                            if(ex.message === 'Network Error')
                                fileInfo.error = 'Network Error';
                            else
                                fileInfo.error = ex.message;
                            fileInfo.informListener({'status': 'error', 'errorMessage': ex.message});
                            fileInfo.isUploading = false;
                        });
                    }
                }
            }else{
                throw Error("Authorization Failed. Please login again.");
            }
        }catch(ex){
            this.stop();
            if(ex.message === 'Network Error')
                fileInfo.error = 'Network Error';
            else{
                fileInfo.error = ex.message;
            }
            fileInfo.informListener({'status': 'error', 'errorMessage': ex.message});
            fileInfo.isUploading = false;
            
        }
    }

    /*
     * method to upload error files
     */
    uploadErrorFiles = (allErrorFiles: Boolean = true) => {
        if(this.sfApi.isAuthorized()){
            const organizationId = this.accessManager.getUserContextSync().organizationId;
            let errorFileInfos : FileInfo[] = [];
            this.fileInfos.forEach( (fileInfo) => {
                if(fileInfo.status === 'Unsynced' && fileInfo.isUploading === false && fileInfo.organizationId === organizationId){
                    if(allErrorFiles){
                        errorFileInfos.push(fileInfo);
                    }else if(fileInfo.submissionId === this.submissionId){
                        errorFileInfos.push(fileInfo);
                    }
                }
            });
    
            for(var fileInfo of errorFileInfos){
                this.uploadFileToSF(fileInfo);
            }
        }
        return true;
    }

    /*
     * method to sync fileinfos with db 
     */
    private async syncFileInfosWithDB(){
        try{
            let dbFileInfos = await getFilesItemInDB();
            for(let dbFileInfo of dbFileInfos){
                let uploaderFileInfo = this.fileInfos.find((obj) => obj.LocalId === dbFileInfo.id);
                if(!uploaderFileInfo && dbFileInfo.ContentDocumentId === 'Unsynced'){
                    const blob = this.dataURItoBlob(dbFileInfo.result);
                    const file = new File([blob], dbFileInfo.fileName);
                    this.fileInfos.push(new FileInfo(file, dbFileInfo.path, dbFileInfo.submissionId, dbFileInfo.organizationId, dbFileInfo.id));
                }
                if(uploaderFileInfo && uploaderFileInfo.ContentDocumentId !== dbFileInfo.ContentDocumentId ){
                    uploaderFileInfo.ContentDocumentId = dbFileInfo.ContentDocumentId;
                    uploaderFileInfo.status = SYNCED;
                    uploaderFileInfo.error = '';
                }
            }
        }catch(ex){
            console.log(ex);
        }
    }

    /**
     * Starts the file upload manager service
     */
    start() {
        console.log('Starting file uploader');
        this.fileSyncIntervalId = setInterval(() => {
            return this.uploadErrorFiles(true); 
        }, 4000) as unknown as number;
    };

    /**
     * Stops the file upload manager service
     */
    stop() {
        
        console.log("Stopping file uploader!!!");
        if(this.fileSyncIntervalId !== NOT_STARTED) {
            clearInterval(this.fileSyncIntervalId);
            this.fileSyncIntervalId = NOT_STARTED;
        }
    }

    /*
     * method to delete files from db related to the given submission Id
     */
    static async deleteImagesFromDB(submissionId){
        await deleteDBImagesForSubmission(submissionId);
    }

    
}

/*
 * File info class to store information of file uploads
 */
export class FileInfo {
    file : File;
    Name : string;
    LocalId: string;
    ContentDocumentId: string = '';
    error: string = '';
    status: string = 'Unsynced';
    path: string;
    submissionId : string;
    organizationId: string = '';
    progress: number = -1;
    onProgress: Function = null as unknown as Function;
    isUploading: Boolean = false;

    /*
     * constructor 
     */
    constructor( file: File, path: string, submissionId, organizationId, LocalId : string = ''){
        LocalId === ''? this.LocalId = uuidv4(): this.LocalId = LocalId;
        this.file  = file;
        this.path = path; 
        this.Name = file.name;
        this.submissionId = submissionId;
        this.organizationId = organizationId;
    }

    /*
     * method to listen the message for progress of file upload
     */
    informListener(message: any) {
        if(this.onProgress) {
            try {
                this.onProgress(message);
            } catch(ex) {
                console.log('Invalid handler found, received error ', ex);
            }
        }
    }
}
