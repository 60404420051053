import React, { useContext, useEffect } from 'react'; 
import { withJsonFormsControlProps } from "@jsonforms/react";
import Checkbox from "@salesforce/design-system-react/components/checkbox";
import { isSalesforceBuild } from '../salesforceBuild';
import CQSubmissionContext  from '../context/CQSubmissionContext'; 
import { setEditedValueinOption, getEditedValueFromOption } from 'services/data-changeindicator.service';


const CQCheckboxControl = ({data,handleChange,path,required,visible, label,enabled,uischema,errors,schema}: any) => {
  const submissionContext = useContext(CQSubmissionContext);

  useEffect(() => {
    if(!data && schema.hasOwnProperty('default')){
      if(schema?.default.length){
        handleCheckboxChange({target : { checked : schema.default}});
      }
    }
  },[schema])
  
  const handleCheckboxChange = (evt:any)=>{
    data = evt.target.checked;
    handleChange(path,data);
    uischema = setEditedValueinOption(data, submissionContext.submission, path, uischema); 
  }

  let customCheckboxLabel = {'label' : label};
  return (
    <>
    {visible?
      <Checkbox
        className={ !isSalesforceBuild() && getEditedValueFromOption(uischema) ? 'cq-input-edit' : ''} 
        labels= {customCheckboxLabel}
        onChange={handleCheckboxChange}
        required={required}
        checked={data}
        errorText={errors}
        disabled={isSalesforceBuild() || !enabled}
      />:''}
    </>
  );
};

export default withJsonFormsControlProps(CQCheckboxControl);